import React from "react"
import Layout from "../../components/Layout"
import ProtectedPage from '../../components/common/ProtectedPage'
import CreateHerbReview from "../../components/CreateHerbReview"

export default () => (
    <Layout seoProps={{title: "Create Herb Review"}}>
        <ProtectedPage>
            <CreateHerbReview />
        </ProtectedPage>
    </Layout>

)

